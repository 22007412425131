import React from 'react';

import { getSortedSrcSet } from './logic';

interface IProps {
    altText: string;
    style?: Record<string, any>;
    className: string;
    size: string;
    srcSet: string;
}

interface IState {
    sortedSrcSet: Array<string | Array<number>>;
    sourceUrl: string;
    afterResize: boolean;
}

class ScalableImage extends React.Component<IProps, IState> {
    state = {
        sortedSrcSet: [],
        sourceUrl: '',
        afterResize: false,
    };

    static defaultProps = {
        altText: 'Image on page',
    };

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.afterResize === false) {
            const { srcSet, size } = props;
            const sortedUrlSources = getSortedSrcSet(srcSet);
            const [selectedImage] = sortedUrlSources;
            return {
                sourceUrl: selectedImage[0],
                sizes: selectedImage[2],
                sortedSrcSet: sortedUrlSources,
            };
        }

        return current_state;
    }

    componentDidMount() {
        const { srcSet, size } = this.props;
        const sortedUrlSources = getSortedSrcSet(srcSet);
        let [selectedImage] = sortedUrlSources.filter(
            (el) => Number(String(el[1]).replace('w', '')) >= window.innerWidth,
        );
        if (!selectedImage) {
            selectedImage = sortedUrlSources[sortedUrlSources.length - 1];
        }

        this.setState({
            sourceUrl: String(selectedImage[0]),
            sortedSrcSet: sortedUrlSources,
            afterResize: true,
        });
    }

    render() {
        const { altText, style = {}, className } = this.props;
        const { sourceUrl } = this.state;
        return (
            <img
                loading="lazy"
                alt={altText}
                src={sourceUrl}
                style={style}
                className={className}
            />
        );
    }
}

export default ScalableImage;
