import { useActions, LangCtx, useContextState } from '@components/contexted';
import { AnimatedLink } from '@components/shared';
import { Link } from 'gatsby';
import React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

import Dropdown from './Dropdown/Dropdown';
import Hamburger from './Hamburger';
import LanguagSwitcher from './Language/LanguagSwitcher';
import LogoSVG from './logo';
import * as S from './styles';
import useScrollHeight from './useScrollHeight';

const Header = (props) => {
    const { translationUrls } = props;
    const [isOpen, setOpen] = useState<boolean>(false);
    const { height } = useScrollHeight();
    const { language } = useContextState(LangCtx, ['language']);

    const links = {
        en: [
            { name: 'Home', url: `/${language}` },
            {
                name: 'Departments',
                url: `/${language}/research-and-development`,
                drop: true,
                sublinks: [
                    {
                        name: 'Research and Development R&D',
                        url: '',
                        drop: true,
                        itemLinks: [
                            {
                                name: 'Skin penetration test',
                                url: `/${language}/skin-penetration-test`,
                            },
                            {
                                name: 'Physicochemical tests',
                                url: `/${language}/physicochemical-tests`,
                            },
                            {
                                name: 'Microbiological tests',
                                url: `/${language}/microbiological-tests`,
                            },
                            {
                                name: 'Dermatological tests',
                                url: `/${language}/dermatological-tests`,
                            },
                            {
                                name: 'Application tests',
                                url: `/${language}/application-tests`,
                            },
                            {
                                name: 'Instrumental testing of skin and hair',
                                url: `/${language}/instrumental-testing-of-skin-and-hair`,
                            },
                            {
                                name: 'Marketing research',
                                url: `/${language}/marketing-research`,
                            },
                            {
                                name: 'Safety assessment of cosmetics',
                                url: `/${language}/safety-assessment-of-cosmetics`,
                            },
                            {
                                name: 'Efficacy and safety testing of medical products',
                                url: `/${language}/testing-of-medical-products`,
                            },
                            {
                                name: 'Cosmetics and household chemicals packaging testing',
                                url: `/${language}/packaging-testing`,
                            },
                            {
                                name: 'Other tests – adjusted to the client’s needs',
                                url: `/${language}/other-tests`,
                            },
                            { name: 'PAO test', url: `/${language}pao-test` },
                            {
                                name: 'T-Shirt tests)',
                                url: `/${language}/t-shirt-tests`,
                            },
                            {
                                name: 'Test of 26 allergens in cosmetics',
                                url: `/${language}/26-allergens-test`,
                            },
                            {
                                name: 'Tests for products intended for specific target groups',
                                url: `/${language}/specific-group-tests`,
                            },
                        ],
                    },
                    {
                        name: 'Biocidal products',
                        url: 'biocidal-products',
                        drop: true,
                        itemLinks: [
                            {
                                name: 'Innovative biocidal product formulations',
                                url: `/${language}/innovative-biocidal-product-formulations`,
                            },
                            {
                                name: 'Efficacy testing of biocidal products',
                                url: `/${language}/efficacy-testing-of-biocidal-products`,
                            },
                            {
                                name: 'SDS for biocidal products',
                                url: `/${language}/sds`,
                            },
                            {
                                name: 'Registration of biocidal products with URPL',
                                url: `/${language}/urpl`,
                            },
                        ],
                    },
                    {
                        name: 'Prescribing and contract manufacturing',
                        url: '',
                        drop: true,
                        itemLinks: [
                            {
                                name: 'Development of recipes for cosmetic products and household chemistry',
                                url: `/${language}/development-of-recipes`,
                            },
                            {
                                name: 'Production, packaging, labelling',
                                url: `/${language}/production-packaging-labelling`,
                            },
                        ],
                    },
                    {
                        name: 'Additional services',
                        url: '',
                        drop: true,
                        itemLinks: [
                            {
                                name: 'Development of cosmetic product Dossiers',
                                url: `/${language}/dossier-development`,
                            },
                            {
                                name: 'Development of material safety data sheets (MSDS)',
                                url: `/${language}/material-safety-data-sheets`,
                            },
                            {
                                name: 'Essential oils – borderline products',
                                url: `/${language}/essential-oils`,
                            },
                            {
                                name: 'Toxicological evaluation of cosmetic products, dietary supplements, household chemistry, medicinal / medical products and others',
                                url: `/${language}/toxicological-evaluation`,
                            },
                            {
                                name: 'Supervision of application tests by an ophthalmologist',
                                url: `/${language}/supervision-of-application-tests`,
                            },
                            {
                                name: 'Products for animals',
                                url: `/${language}/products-for-animals`,
                            },
                            {
                                name: 'Dyes and inks for permanent make-up and tattoo',
                                url: `/${language}/ink-dyes`,
                            },
                            {
                                name: 'Opinions on products intended for children, pregnant women, after childbirth, and sun care products for children',
                                url: `/${language}/opinion-on-products-for-children`,
                            },
                            {
                                name: 'Development of specialist documentation for dietary supplements for animals',
                                url: `/${language}/specialised-documentation-for-animal-diet-supplements`,
                            },
                        ],
                    },
                ],
            },
            { name: 'IVD products', url: `/${language}/ivd-products` },
            { name: 'Legal service', url: `/${language}/legal-service` },
            {
                name: 'Marketing service',
                url: `/${language}/marketing-service`,
            },
            {
                name: 'Technology transfer',
                url: `/${language}/technology-transfer`,
            },
            { name: 'Our partners', url: `/${language}/our-partners` },
            { name: 'In the media', url: `/${language}/in-media` },
            { name: 'Blog', url: `/${language}/blog` },
            { name: 'Contact', url: `/${language}/contact` },
        ],
        pl: [
            { name: 'Główna', url: `/${language}` },
            {
                name: 'Dzialy',
                url: `/${language}/research-and-development`,
                drop: true,
                sublinks: [
                    {
                        name: 'Badania i rozwój R&D',
                        url: '',
                        drop: true,
                        itemLinks: [
                            {
                                name: 'Badanie przenikalności w głąb skóry',
                                url: `/${language}/skin-penetration-test`,
                            },
                            {
                                name: 'Badania fizykochemiczne',
                                url: `/${language}/physicochemical-tests`,
                            },
                            {
                                name: 'Badania mikrobiologiczne',
                                url: `/${language}/microbiological-tests`,
                            },
                            {
                                name: 'Badania dermatologiczne',
                                url: `/${language}/dermatological-tests`,
                            },
                            {
                                name: 'Badania aplikacyjne',
                                url: `/${language}/application-tests`,
                            },
                            {
                                name: 'Badania aparaturowe skóry i włosów',
                                url: `/${language}/instrumental-testing-of-skin-and-hair`,
                            },
                            {
                                name: 'Badania marketingowe',
                                url: `/${language}/marketing-research`,
                            },
                            {
                                name: 'Ocena bezpieczeństwa kosmetyków',
                                url: `/${language}/safety-assessment-of-cosmetics`,
                            },
                            {
                                name: 'Badania produktów medycznych pod kątem efektywności i bezpieczeństwa',
                                url: `/${language}/testing-of-medical-products`,
                            },
                            {
                                name: 'Badania opakowań kosmetycznych i chemii gospodarczej',
                                url: `/${language}/packaging-testing`,
                            },
                            {
                                name: 'Inne badania – dostosowane do potrzeb zleceniodawcy',
                                url: `/${language}/other-tests`,
                            },
                            {
                                name: 'Badanie PAO',
                                url: `/${language}pao-test`,
                            },
                            {
                                name: 'Testy koszulkowe (T-Shirt tests)',
                                url: `/${language}/t-shirt-tests`,
                            },
                            {
                                name: 'Badanie 26 alergenów w kosmetyku',
                                url: `/${language}/26-allergens-test`,
                            },
                            {
                                name: 'Badania dla produktów przeznaczonych dla specjalnych grup docelowych',
                                url: `/${language}/specific-group-tests`,
                            },
                        ],
                    },
                    {
                        name: 'Produkty biobójcze',
                        url: '',
                        drop: true,
                        itemLinks: [
                            {
                                name: 'Innowacyjne receptury produktów biobójczych',
                                url: `/${language}/innovative-biocidal-product-formulations`,
                            },
                            {
                                name: 'Badania skuteczności produktów biobójczych',
                                url: `/${language}/efficacy-testing-of-biocidal-products`,
                            },
                            {
                                name: 'Karty SDS (MSDS) dla produktów biobójczych',
                                url: `/${language}/sds`,
                            },
                            {
                                name: 'Rejestracja produktów biobójczych w URPL',
                                url: `/${language}/urpl`,
                            },
                        ],
                    },
                    {
                        name: 'Recepturowanie i produkcja kontraktowa',
                        url: '',
                        drop: true,
                        itemLinks: [
                            {
                                name: 'Opracowywanie receptur produktów kosmetycznych i chemii gospodarczej',
                                url: `/${language}/development-of-recipes`,
                            },
                            {
                                name: 'Produkcja, konfekcjonowanie, etykietowanie',
                                url: `/${language}/production-packaging-labelling`,
                            },
                        ],
                    },
                    {
                        name: 'Usługi dodatkowe',
                        url: '',
                        drop: true,
                        itemLinks: [
                            {
                                name: 'Opracowywanie Dossier produktów kosmetycznych',
                                url: `/${language}/dossier-development`,
                            },
                            {
                                name: 'Opracowywanie kart charakterystyki surowców',
                                url: `/${language}/material-safety-data-sheets`,
                            },
                            {
                                name: 'Olejki eteryczne – produkty z pogranicza',
                                url: `/${language}/essential-oils`,
                            },
                            {
                                name: 'Ocena toksykologiczna produktów kosmetycznych, suplementów diety, chemii gospodarczej, produktów leczniczych/ medycznych i innych',
                                url: `/${language}/toxicological-evaluation`,
                            },
                            {
                                name: 'Nadzór badań aplikacyjnych przez lekarza okulistę',
                                url: `/${language}/supervision-of-application-tests`,
                            },
                            {
                                name: 'Produkty dla zwierząt',
                                url: `/${language}/products-for-animals`,
                            },
                            {
                                name: 'Barwniki i tusze do makijażu permanentnego i tatuażu',
                                url: `/${language}/ink-dyes`,
                            },
                            {
                                name: 'Opiniowanie produktów przeznaczonych dla dzieci, kobiet w ciąży, po porodzie, produktów do opalania dla dzieci',
                                url: `/${language}/opinion-on-products-for-children`,
                            },
                            {
                                name: 'Opracowanie specjalistycznej dokumentacji do suplementów diety dla zwierząt',
                                url: `/${language}/specialised-documentation-for-animal-diet-supplements`,
                            },
                        ],
                    },
                ],
            },
            { name: 'Wyroby IVD', url: `/${language}/ivd-products` },
            { name: 'Obsługa prawna', url: `/${language}/legal-service` },
            {
                name: 'Obsługa marketingowa',
                url: `/${language}/marketing-service`,
            },
            {
                name: 'Transfer technologii',
                url: `/${language}/technology-transfer`,
            },
            { name: 'Nasi partnerzy', url: `/${language}/our-partners` },
            { name: 'W mediach', url: `/${language}/in-media` },
            { name: 'Blog', url: `/${language}/blog` },
            { name: 'Kontakt', url: `/${language}/contact` },
        ],
    };
    return (
        <S.Header smaller={height > 135}>
            <Helmet>
                <body className={isOpen ? 'blocked' : ''} />
            </Helmet>

            <Hamburger
                isOpen={isOpen}
                setOpen={setOpen}
                smaller={height > 135}
            />

            <S.InnerWrapper>
                <AnimatedLink>
                    <S.Logo>
                        <Link to={`/${language}`}>
                            <LogoSVG />
                        </Link>
                    </S.Logo>
                </AnimatedLink>

                <S.Navbar isOpen={isOpen}>
                    <S.LinksList>
                        {links[language].map((link) => (
                            <>
                                {link.drop ? (
                                    <Dropdown link={link} key={link.url} />
                                ) : (
                                    <li key={link.url}>
                                        <AnimatedLink>
                                            <S.GatsbyLink to={link.url}>
                                                {link.name}
                                            </S.GatsbyLink>
                                        </AnimatedLink>
                                    </li>
                                )}
                            </>
                        ))}
                        <LanguagSwitcher translationUrls={translationUrls} />
                    </S.LinksList>
                </S.Navbar>
            </S.InnerWrapper>
        </S.Header>
    );
};

export default Header;
