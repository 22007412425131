import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        outline: none;
    }

    html {
        scroll-behavior: smooth;
        font-size: ${(props) => props.theme.fonts.size.p};
    }

    body {
        font-family: ${({ theme }) => theme.fonts.family.main};
        overflow: hidden;
        overflow-y: scroll;

        &.blocked {
            overflow-y: hidden
        }

        h1{
        font-size: ${(props) => props.theme.fonts.size.h1};
        }

        h2{
            font-size: ${(props) => props.theme.fonts.size.h2};
        }

        h3{
            font-size: ${(props) => props.theme.fonts.size.h3};
        }

        h4{
            font-size: ${(props) => props.theme.fonts.size.h4};
        }

        p{
            font-size: ${(props) => props.theme.fonts.size.p};
        }

        small{
            font-size: ${(props) => props.theme.fonts.size.small};
        }
    }

    #___gatsby {
        display: block;
        margin: auto;
    }

    .loader {
    position: fixed;
    background: $white;
    color: $main_navy;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000 * 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    
}

`;
