import { Link } from 'gatsby';
import styled from 'styled-components';

import { NavbarProps, HeaderProps, HamburgerProps } from './styles.interfaces';

export const GatsbyLink = styled(Link).attrs({
    activeClassName: 'active',
    partialyActive: true,
})`
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    font-size: 1.5rem;
    position: relative;
    transition: margin-left 250ms ease;

    @media (min-width: 1366px) {
        color: ${(props) => props.theme.colors.white};
    }

    &.active {
        font-weight: 700;
    }
`;

export const Hamburger = styled.div<HamburgerProps>`
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    position: absolute;
    right: 0;
    top: ${({ isOpen, smaller }) => (smaller ? '50%' : isOpen ? '25%' : '50%')};
    transform: translate(-50%, -50%);
    z-index: 5;
    transition: top 250ms ease;

    @media (min-width: 1366px) {
        display: none;
    }

    div {
        width: 100%;
        height: 0.25rem;
        border-radius: 3px;
        background: ${(props) =>
            props.isOpen ? props.theme.colors.white : props.theme.colors.black};
        transform-origin: 1px;
        transition: all 250ms ease;

        &:first-child {
            transform: ${(props) =>
                props.isOpen ? 'rotate(45deg)' : 'rorate(0)'};
        }

        &:nth-child(2) {
            opacity: ${(props) => (props.isOpen ? '0' : '1')};
        }

        &:nth-child(3) {
            transform: ${(props) =>
                props.isOpen ? 'rotate(-45deg)' : 'rorate(0)'};
        }
    }
`;

export const Header = styled.header<HeaderProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 0.5rem 1rem;
    border-bottom: 2px solid gray;
    height: ${(props) => (props.smaller ? 74 : 120)}px;
    background: white;
    transition: height 250ms ease;
    display: flex;
`;

export const InnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: ${(props) => props.theme.layout.maxWidth};
    padding: 0 ${(props) => props.theme.layout.paddingX};
    width: 100%;
    margin: 0 auto;
`;

export const Logo = styled(Link).attrs({
    to: '/',
})`
    width: auto;
    cursor: pointer;
`;

export const Navbar = styled.nav<NavbarProps>`
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100vh;
    transform: ${(props) =>
        props.isOpen ? 'translateX(0)' : 'translateX(100%)'};
    background: ${(props) => props.theme.colors.primary[900]};
    transition: transform 250ms ease;

    @media (min-width: 1366px) {
        position: relative;
        width: auto;
        height: 100%;
        transform: none;
        background: transparent;
    }

    ${GatsbyLink} {
        color: ${(props) =>
            props.isOpen ? props.theme.colors.white : props.theme.colors.black};
    }
`;

export const LinksList = styled.ul`
    width: 100%;
    height: 100%;
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;

    @media (min-width: 1366px) {
        flex-direction: row;
        margin-top: 0;
    }

    li {
        padding: 0.5rem 2rem;
    }
`;
