import styled from 'styled-components';

const DrodownList = styled.ul`
    width: 300px;
    position: absolute;
    top: 40px;
    list-style: none;
    text-align: start;
    z-index: 9999;

    li {
        position: relative;
        cursor: pointer;
        width: 100%;
        background-color: white;
        color: black;
        border: 1px solid black;

        a {
            text-decoration: none;
        }
    }
`;

export { DrodownList };
