import styled from 'styled-components';

export const Layout = styled.div`
    overflow: hidden;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Main = styled.main`
    margin: 120px auto;
    max-width: ${(props) => props.theme.layout.maxWidth};
    padding: 0 ${(props) => props.theme.layout.paddingX};
    width: 100%;
`;
