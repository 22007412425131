import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    layout: {
        maxWidth: '1320px',
        paddingX: '8px',
    },
    colors: {
        primary: {
            100: '#edebff',
            300: '#cfc9ff',
            500: '#7f6fff',
            700: '#1c00f9',
            900: '#1301a3',
        },
        secondary: {
            100: '##f6feff',
            300: '#eafdff',
            500: '#c4f8fe',
            700: '#88e3ee',
            900: '#3db3c0',
        },
        gray: {
            100: '#eceef0',
            300: '#bcc8d8',
            500: '#8595a8',
            700: '#3a4c62',
            900: '#11151f',
        },
        green: {
            100: '#e9fcd5',
            300: '#a8f07f',
            500: '#50ce2d',
            700: '#1f9416',
            900: '#08620f',
        },
        yellow: {
            100: '#fff7cd',
            300: '#ffe169',
            500: '#ffc005',
            700: '#b78002',
            900: '#7a4f00',
        },
        red: {
            100: '#ffe6d5',
            300: '#ffa081',
            500: '#ff3f2d',
            700: '#b71623',
            900: '#7a0824',
        },
        white: '#fff',
        black: '#111',
    },
    fonts: {
        family: {
            main: 'Poppins, sans-serif',
            secondary: 'Poppins, sans-serif',
        },
        size: {
            h1: '3em',
            h2: '2.5em',
            h3: '2em',
            h4: '1.5rem',
            p: '16px',
            small: '0.667rem',
        },
    },
    shadows: {
        light: `0px 5px 10px 0px rgba(221, 226, 243, 0.2)`,
        medium: '0px 5px 10px 0px rgba(221, 226, 243, 0.2)',
        big: '0px 5px 10px 0px rgba(221, 226, 243, 0.2)',
    },
};

export default theme;
