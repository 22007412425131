import styled from 'styled-components';

export const Span = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #ccc;
    transition: all 400ms ease;
    border-radius: 16px;

    display: flex;
    justify-content: space-around;
    align-items: center;

    // &::before {
    //     position: absolute;
    //     content: '';
    //     height: 20px;
    //     width: 20px;
    //     top: 2px;
    //     left: 2px;
    //     background-color: white;
    //     border-radius: 12px;
    //     transition: all 400ms ease;
    // }
`;

export const Switcher = styled.label`
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
`;

export const Input = styled.input.attrs(() => ({
    type: 'checkbox',
}))`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${Span} {
    }

    &:checked + ${Span}:before {
        transform: translateX(26px);
    }
`;
