import { Head, Footer, Header } from '@components/layout';
import { useLocation } from '@reach/router';
import React from 'react';
import Modal from 'react-modal';
import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from '../../../styles/global';
import theme from '../../../styles/theme';
import * as S from './styles';

Modal.setAppElement('#___gatsby');

const Layout = ({
    children,
    siteMetaData,
    seo,
    imBusy,
    headerDisabled,
    translationUrls
} : any) => {
    let defaultTranslation = {
        pl: '/pl',
        en: '/en',
    };
    if (translationUrls) {
        defaultTranslation = translationUrls;
    }
    const location = useLocation();
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <S.Layout>
                <Head
                    seo={seo}
                    siteMetaData={siteMetaData}
                    location={location}
                />
                {!headerDisabled && <Header translationUrls={translationUrls}/>}

                <S.Main>{children}</S.Main>
                <Footer />
            </S.Layout>
        </ThemeProvider>
    );
};

export default Layout;
