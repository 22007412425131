import { useLocation } from '@reach/router';
import React from 'react';

import * as S from './styles';

const Footer = () => {
    return (
        <S.Footer>
            <S.Container></S.Container>
        </S.Footer>
    );
};

export default Footer;
