import styled from 'styled-components';

const DrodownItemList = styled.ul`
    width: 450px;
    position: absolute;
    left: 300px;
    top: 0px;
    list-style: none;
    text-align: start;
    z-index: 9999;

    li {
        position: relative;
        cursor: pointer;
        width: 100%;
        background-color: white;
        color: black;
        text-decoration: none;
        border: 1px solid black;
    }
`;

export { DrodownItemList };
