import React, { useState } from 'react';
import { useActions, LangCtx, useContextState } from "@components/contexted"
import * as S from './styles';
import { navigate } from "gatsby"
import { transform } from '@babel/core';

interface LanguageSwitcherProps {
    translationUrls: Object
}
const LanguagSwitcher: React.FC<LanguageSwitcherProps> = (props): JSX.Element => {
    const { translationUrls } = props
    const { language } = useContextState(LangCtx, ["language"])
    const { setLangPl, setLangEn } = useActions(LangCtx, [
        "setLangPl",
        "setLangEn",
    ])

    const setCurrentPl = () => {
        setLangPl()
        localStorage.setItem("lang", "pl")
        navigate(translationUrls.pl)
    }

    const setCurrentEn = () => {
        setLangEn()
        localStorage.setItem("lang", "en")
        navigate(translationUrls.en)
    }
    const log = (event) => {
        event.target.checked && setCurrentPl();
        !event.target.checked && setCurrentEn();
    };

    return (
        <S.Switcher>
            {/* <S.Input onClick={log} /> */}
            <S.Span>
                <small onClick={() => setCurrentEn()}>EN</small>
                <small onClick={() => setCurrentPl()}>PL</small>
            </S.Span>
        </S.Switcher>
    );
};

export default LanguagSwitcher;
