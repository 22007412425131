import { AnimatedSection, AnimatedButton } from '@components/shared';
import React from 'react';
import ReactDOM from 'react-dom';

import {
    modal,
    modal__close,
    modal__bg,
    modal__content,
    modal__header,
    modal__header__close,
    small,
} from './modal.module.scss';

interface IProps {
    title?: string;
    close?: boolean;
    lockBackground?: boolean;
    bgColor?: string;
    noAnimation?: boolean;
    onClose?: () => boolean;
    className: string;
    isDev?: boolean;
}

interface IState {
    imOpen: boolean;
}

class Modal extends React.Component<IProps, IState> {
    state = { imOpen: false };

    toggle = (cond: boolean) => {
        this.setState({
            imOpen: cond || !this.state.imOpen,
        });
    };

    render() {
        const {
            title = 'Modal Header',
            close = true,
            lockBackground = false,
            bgColor = 'auto',
            noAnimation = false,
            onClose = () => false,
            className,
            isDev = false,
        } = this.props;
        const { imOpen } = this.state;
        if (!imOpen) {
            return null;
        }
        return ReactDOM.createPortal(
            <React.Fragment>
                <a
                    className={modal__bg}
                    style={{
                        backgroundColor: bgColor,
                    }}
                    onClick={() => {
                        if (!lockBackground) {
                            this.toggle(false);
                            onClose();
                        }
                    }}
                />
                <div className={[modal, className, isDev && small].join(' ')}>
                    <div
                        className={[
                            modal__header,
                            title === '' ? modal__header__close : '',
                        ].join(' ')}>
                        {title && <h3>{title}</h3>}
                        {close && (
                            <AnimatedButton
                                className={modal__close}
                                onClick={() => {
                                    this.toggle(false);
                                    onClose();
                                }}>
                                <i className="icofont-close" />
                            </AnimatedButton>
                        )}
                    </div>
                    <AnimatedSection
                        className="modalContent"
                        extraStyle={{
                            width: '100%',
                            height: '100%',
                        }}>
                        <div className={`${modal__content}`}>
                            {this.props.children}
                        </div>
                    </AnimatedSection>
                </div>
            </React.Fragment>,
            document.querySelector('#portal')!,
        );
    }
}

export default Modal;
