import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import React from 'react';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const AnimatedSection = ({ className, children, ...rest }) => {
    const { ref, inView } = useInView();
    const animation = useAnimation();

    useEffect(() => {
        if (inView) {
            animation.start({
                x: 0,
                opacity: 1,
                transition: {
                    duration: 0.5,
                    type: 'spring',
                },
            });
        }
    }, [inView]);
    return (
        <motion.section
            {...rest}
            ref={ref}
            className={className}
            animate={animation}
            initial={{ x: '-50vw' }}>
            {children}
        </motion.section>
    );
};

export default AnimatedSection;
