import { Link } from 'gatsby';
import React, { useState } from 'react';

import * as S from './DropdownItem.styles';
import type { ISubLink } from './types';

interface DropdownItemProps {
    link: ISubLink;
}

const DropdownItem: React.FC<DropdownItemProps> = ({ link }) => {
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    const handleToggle = () => {
        setIsDropdownActive((prev) => !prev);
    };

    return (
        <li onMouseEnter={handleToggle} onMouseLeave={handleToggle}>
            <Link to={`${link.url}`}>{link.name} &gt;</Link>
            {isDropdownActive && (
                <S.DrodownItemList>
                    {link.itemLinks.map((item: any) => (
                        <li key={item.name}>
                            <Link to={`${item.url}`}>{item.name}</Link>
                        </li>
                    ))}
                </S.DrodownItemList>
            )}
        </li>
    );
};

export default DropdownItem;
