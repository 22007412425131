import styled from 'styled-components';

export const Footer = styled.footer`
    width: 100%;
    background-color: ${(props) => props.theme.colors.black};
    padding: 75px;
    @media (max-width: 768px) {
        padding: 0;
    }
`;

export const Container = styled.div`
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: white;

    @media (max-width: 768px) {
        max-width: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
`;
