import { Link } from 'gatsby';
import React, { useState } from 'react';

import * as S from './Dropdown.styles';
import DropdownItem from './DropdownItem';
import type { ILink } from './types';

interface DropdownProps {
    link: ILink;
}

const Dropdown: React.FC<DropdownProps> = ({ link }) => {
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    const handleToggle = () => {
        setIsDropdownActive((prev) => !prev);
    };

    return (
        <li onMouseEnter={handleToggle} onMouseLeave={handleToggle}>
            <Link to={`${link.url}`}>{link.name} V</Link>
            {isDropdownActive && (
                <S.DrodownList>
                    {link.sublinks.map((item: any) => (
                        <>
                            {item.drop ? (
                                <DropdownItem link={item} key={link.name} />
                            ) : (
                                <li key={item.name}>
                                    <Link to={`${item.url}`}>{item.name}</Link>
                                </li>
                            )}
                        </>
                    ))}
                </S.DrodownList>
            )}
        </li>
    );
};

export default Dropdown;
