import React from 'react';

import * as S from './styles';

const Hamburger = ({ isOpen, setOpen, smaller }) => {
    return (
        <S.Hamburger
            isOpen={isOpen}
            onClick={() => setOpen((prevState) => !prevState)}
            smaller={smaller}>
            <div />
            <div />
            <div />
        </S.Hamburger>
    );
};

export default Hamburger;
